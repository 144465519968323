import moment from 'moment';

export const rules = {
    required: (value) => !!value || "This field is required",
    dateRange: (d1, d2) => {
        if (d2 == null) return true;
        else {
            const valid = moment(d1).isSameOrBefore(moment(d2));
            if (!valid) {
                return 'End date must be same or after the start date'
            }
            else return true;
        }
    },
    timeRange: (t1, t2) => {
        if (t2 == null) return true;
        else {
            const valid = moment(t1, 'HH:mm').isBefore(moment(t2, 'HH:mm'));
            if (!valid) {
                return 'End time must be after the start time'
            }
            else return true;
        }
    },
    minDate: (min, date) => {
        if (min == null || date == null) return true;
        else {
            const minDate = moment(min);
            const valid = moment(date).isSameOrAfter(minDate);
            if (!valid) {
                return `Date can not be before ${minDate.format('ddd, MMM DD, yyyy')}`
            }
            else return true;
        }
    },
    maxDate: (max, date) => {
        if (max == null || date == null) return true;
        else {
            const maxDate = moment(max);
            const valid = moment(date).isSameOrBefore(maxDate);
            if (!valid) {
                return `Date can not be after ${maxDate.format('ddd, MMM DD, yyyy')}`
            }
            else return true;
        }
    },
    emailMatch: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
    },
    phoneMatch: (value) => {
        if (value) {
            if (!isNaN(value)) {
                value = value.toString();
            }

            const phoneNumber = value.replace(/\D/g, "");
            const invalidLength = phoneNumber.length < 10 || phoneNumber > 11;

            return invalidLength || "Invalid phone number";
        } else {
            //Not required
            return true;
        }
    },
}