<template>
  <div>
    <div class="d-flex justify-space-between flex-md-row flex-column">
      <v-text-field
        class="ma-5"
        v-model="startDate"
        :label="$vuetify.lang.t('$vuetify.base.startDate')"
        type="date"
        clearable
        :rules="[rules.required]"
      ></v-text-field>
      <v-text-field
        class="ma-5"
        v-model="endDate"
        :label="$vuetify.lang.t('$vuetify.base.endDate')"
        type="date"
        clearable
        :rules="[rules.dateRange(startDate, endDate)]"
      ></v-text-field>
    </div>
    <div>
      <v-btn @click="submit()">Submit</v-btn>
    </div>
    <div>
      {{ inputs }}
      {{ outputs }}
    </div>
  </div>
</template>

<script>
import { rules } from "@/store/util/rules.js";
import moment from "moment";

export default {
  data: () => {
    return {
      rules,
      startDate: null,
      endDate: null,
      inputs: [],
      outputs: [],
    };
  },
  created() {
    this.startDate = moment().subtract(1, "day").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
  },
  methods: {
    async loadProcessInputs() {
      const params = {
        start: this.startDate,
        end: this.endDate,
      };
      return await this.$axios
        .get("/processInput/between-dates", { params })
        .then((response) => {
          this.inputs = response.data;
        });
    },
    async loadProcessOutputs() {
      const params = {
        start: this.startDate,
        end: this.endDate,
      };
      return await this.$axios
        .get("/processOutput/between-dates", { params })
        .then((response) => {
          this.inputs = response.data;
        });
    },
    async loadInventories() {
      const inputInventoryIds = this.inputs.map((i) => i.inventoryId);
      const outputInventoryIds = this.outputs.map((i) => i.inventoryId);
      const allIds = [...inputInventoryIds, ...outputInventoryIds];

      const params = {
        ids: allIds.join(","),
      };
      this.$axios.get("/inventory/by-ids", { params }).then((response) => {
        console.log(response.data);
      });
    },
    async submit() {
      await this.loadProcessOutputs();
      await this.loadProcessInputs();

      this.loadInventories();
    },
  },
};
</script>

<style></style>
